<template>
  <div class="login">
    <div class="container h-100">
      <div class="containerRow row h-100">
        <div class="col-12 col-md-6 d-flex align-items-center">
          <img
            src="../../assets/logo-transparent-cropped.png"
            alt="Vax Trax Global Logo"
            class="w-100"
          />
        </div>
        <div
          class="
            col-12 col-md-6
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
        >
          <div class="loginPanel">
            <h1 class="text-center mb-4 mt-3">Login</h1>
            <!-- <p class="text-center mb-4">
              Do you have a vendor account?
              <router-link to="/login">Sign In</router-link>
            </p> -->

            <b-form @submit.prevent="onSubmit" class="w-100">
              <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
              >
                <div class="inputField">
                  <b-icon icon="envelope"></b-icon>
                  <b-form-input
                    id="input-1"
                    type="email"
                    v-model="form.email"
                  ></b-form-input>
                </div>
              </b-form-group>
              <div class="d-flex justify-content-center align-items-center">
                <span class="text-muted">OR</span>
              </div>
              <b-form-group
                id="input-group-2"
                label="Mobile No:"
                label-for="input-2"
              >
                <div class="row">
                  <div class="col-3 pr-0">
                    <VueCountryCode class="countryCode" :enabledCountryCode = "true" @onSelect="onSelect">
                    </VueCountryCode>
                  </div>
                  <div class="col-9 pl-0">
                    <div class="inputField">
                      <b-icon icon="telephone"></b-icon>
                      <b-form-input
                        id="input-2"
                        type="tel"
                        v-model="form.mobile"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Password:"
                label-for="input-3"
              >
                <div class="inputField">
                  <b-icon icon="lock"></b-icon>

                  <b-form-input
                    id="input-3"
                    type="text"
                    v-model="form.password"
                    required
                    v-if="showPassword"
                  ></b-form-input>
                  <b-form-input
                    id="input-4"
                    type="password"
                    v-model="form.password"
                    required
                    v-else
                  ></b-form-input>
                  <div class="showPasswordIconContainer">
                    <b-icon
                      icon="eye-slash-fill"
                      class="showPassword"
                      v-if="showPassword"
                      @click="toggleShowPassword"
                    ></b-icon>
                    <b-icon
                      icon="eye-fill"
                      class="showPassword"
                      v-else
                      @click="toggleShowPassword"
                    ></b-icon>
                  </div>
                </div>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                class="loginBtn mt-4 mb-3"
              >
                Login
              </b-button>
              <div class="d-flex justify-content-between">
                <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="form.checked"
                    id="checkboxes-4"
                    :aria-describedby="ariaDescribedby"
                    size="md"
                  >
                    <b-form-checkbox value="rememberMe"
                      >Remember Me</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group>

                <router-link to="/forgot-password"
                  >Forgot Password ?</router-link
                >
              </div>
              <div class="d-flex justify-content-between px-3">
                <div
                  v-for="(val, key) in list"
                  :key="key"
                  class="d-flex justify-content-center align-items-center"
                >
                  <input
                    type="radio"
                    name="radio"
                    :value="val"
                    v-model="selected"
                    :id="val"
                    @click="uncheck(val)"
                  />
                  <label :for="val" class="ml-1 mb-0">{{ val }}</label>
                </div>
              </div>
            </b-form>
            <p class="text-center my-4">
              Don't have an account?
              <router-link to="/register">Sign Up</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCountryCode from "vue-country-code-select";
// const twilio = require('twilio');
import twilio from "twilio";
import authy from "authy";
export default {
  data() {
    return {
      form: {
        email: "",
        mobile: "",
        password: "",
      },
      showPassword: false,
      list: ["Vendor", "Lab/Clinic"],
      selectedCountryCode: null,
      selected: false,
      previous: null,
    };
  },
  components: {
    VueCountryCode,
  },
  created() {
    axios
      .get("https://api.vaxtraxglobal.com/api/v1/config/1")
      .then(({ data }) => {
        console.log(data);
        this.countries = data.country;
        // console.log(this.countries)
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    uncheck(val) {
      if (val == this.selected) {
        this.selected = false;
      }
      this.previous = this.selected;
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.selectedCountryCode = dialCode;
    },
    onSubmit() {
      let loginData =
        this.form.email.length > 0
          ? this.form.email
          : `${this.selectedCountryCode}${this.form.mobile}`;
      console.log(loginData);
      axios
        .get("https://api.vaxtraxglobal.com/api/v1/user", {
          headers: {
            role: "user",
            username: loginData,
            password: this.form.password,
          },
        })
        .then((result) => {
          console.log(result.data);
          this.$store.dispatch("setData", result.data);
          this.$store.dispatch("setAuth", true)
          this.$router.push("/dashboard");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  width: 100%;
  background: #005eaa33;

  @media (max-width: 768px) {
    padding-bottom: 10vh;
  }

  .containerRow {
    min-height: 100vh;

    .loginPanel {
      background: #fff;
      width: 80%;
      height: auto;
      min-height: 450px;
      margin: 0 auto;
      padding: 10px 20px;
      border-radius: 40px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      flex-direction: column;

      > h1 {
        font-weight: 800 !important;
      }

      a {
        font-weight: bold;
      }

      form {
        label {
          font-size: 1rem;
        }
        .inputField {
          position: relative;
          > .b-icon {
            position: absolute;
            left: 15px;
            top: 8px;
            font-size: 20px;
          }
          .showPasswordIconContainer {
            position: absolute;
            right: 15px;
            top: 5px;
            font-size: 20px;
          }
        }
        input {
          border-radius: 20px;
          padding-left: 45px;
        }
        .loginBtn {
          width: 100%;
          border-radius: 20px;
        }
        .countryCode {
          border-radius: 20px;
          background: rgb(241, 241, 241);
          width: 80%;
          z-index: 10;
        }
      }

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
}
</style>
